import * as React from "react";
import dayjs from "dayjs";
import { Row, Col, TableColumnsType, Form } from "antd";
import isEqual from "react-fast-compare";

import colors from "src/theme/colors.json";
import {
  BankCode,
  BankDepositMethod,
  BankReportItem,
  BankReportItemTable,
  BankRow,
  BankTransactionType,
  BANK_TYPE,
} from "src/types";

import { getBankFormat, getPhoneFormat } from "src/utils/textOperation/textOperation";
import { getColumnFilters } from "src/utils/table";
import { useBank } from "src/hooks/useTransaction/useBank";
import { useBankReport, UseBankReportFilters } from "src/hooks/useBankReport";

import Button from "src/components/Button/Button";
import BankLogo from "src/components/BankLogo";
import PageHeader from "src/components/PageHeader/PageHeader";
import Space from "src/components/Space/Space";
import DeskPanel from "src/components/DeskPanel/DesktPanel";
import { ChannelColumn, DatePicker, SearchButton, Table } from "src/components";
import { AmountColumn } from "src/components/Table";
import { BankSelect } from "src/components/Bank/BankSelect";

import "./BankReport.scss";

interface IBankReportPageProps { }

const DISPLAY_DATE_FORMAT = "DD/MM/YYYY";
const DISPLAY_TIME_FORMAT = "HH:mm";
const DEFAULT_ACCOUNT_OPTION: BankRow = {
  bankId: "",
  groupBankId: '',
  bankCode: BankCode.NONE,
  bankName: "",
  isAuto: false,
  promptpay: "",
  trueKey: "",
  status: "Closed",
  type: BANK_TYPE.DEPOSIT,
  accountName: "ทุกบัญชี",
  accountNumber: "",
  bankBalance: 0,
  balance: 0,
};

export const BankReportPage: React.FunctionComponent<IBankReportPageProps> = (_) => {
  const [depositMethodFilter, setDepositMethodFilter] = React.useState<BankDepositMethod>(
    BankDepositMethod.SCB_SMS
  );
  const [isDateOpened, setIsDateOpened] = React.useState(false);
  const [fetchFilters, setFetchFilters] = React.useState<UseBankReportFilters>({
    startTime: dayjs().startOf("day").toDate(),
    endTime: dayjs().endOf("day").toDate(),
  });

  const [form] = Form.useForm();
  const { data, isFetching, refetch } = useBankReport(fetchFilters);
  const bankState = useBank();

  const { getDepositBankList, depositBankList } = bankState;
  const getNoneTrueWalletBankList = () =>
    [DEFAULT_ACCOUNT_OPTION].concat(
      depositBankList.filter((b) => b.bankCode !== BankCode.TrueMoney)
    );
  const getDisplayReportResults = () => {
    return data?.results
      .map((rawReport) => {
        return {
          ...rawReport,
          transactionDate: dayjs(rawReport.transactionDate).format(DISPLAY_DATE_FORMAT),
          transactionTime: dayjs(rawReport.transactionDate).format(DISPLAY_TIME_FORMAT),
          createdDate: dayjs(rawReport.createdAt).format(DISPLAY_DATE_FORMAT),
          createdTime: dayjs(rawReport.createdAt).format(DISPLAY_TIME_FORMAT),
          transactionChannel: `${rawReport.pigBankCode} ${rawReport.transactionType}`,
          customerAccountNumber: getBankFormat(rawReport.customerAccountNumber || ""),
          pigAccountNumber: getBankFormat(rawReport.pigAccountNumber || ""),
          bankPromptpayNo: getPhoneFormat(rawReport.bankPromptpayNo || ""),
        };
      }) || [];
  };

  const noneTrueWalletBankList = React.useMemo(getNoneTrueWalletBankList, [depositBankList]);
  const displayReportResults = React.useMemo(getDisplayReportResults, [data, depositMethodFilter]);

  const renderBankAccountCell = (
    bankAccountName: string | null,
    bankAccountNumber: string | null,
    bankPromptpayNo: string | null,
    bankCode?: BankCode | string | null
  ) => {
    return (
      <Space>
        <Col>
          <BankLogo bankCode={(bankCode as BankCode) || undefined} />
        </Col>
        <Col>
          <Row>{bankAccountName}</Row>
          <Row>{bankAccountNumber}</Row>
          <Row>{bankPromptpayNo}</Row>
        </Col>
      </Space>
    );
  };

  const getAllColumns = (): TableColumnsType<BankReportItemTable> => {
    const baseColumn: TableColumnsType<BankReportItemTable> = [
      {
        className: "table-cell",
        title: "วันที่",
        dataIndex: "transactionDate",
        key: "transactionDate",
        filterSearch: true,
        filters: getColumnFilters("transactionDate", displayReportResults || []),
        onFilter: (value, row) => row.transactionDate?.includes(value.toString()) || false,
      },
      {
        className: "table-cell",
        title: "เวลา",
        dataIndex: "transactionTime",
        key: "transactionTime",
        filterSearch: true,
        width: 80,
        filters: getColumnFilters("transactionTime", displayReportResults || []),
        onFilter: (value, row) => row.transactionTime?.includes(value.toString()) || false,
      },
      {
        className: "table-cell",
        title: "บัญชีลูกค้า",
        dataIndex: "customerAccountName",
        key: "customerAccountName",
        filterSearch: true,
        filters: getColumnFilters("customerAccountNumber", displayReportResults || []),
        onFilter: (value, row) => row.customerAccountNumber?.includes(value.toString()) || false,
        render: (value, row) =>
          renderBankAccountCell(
            value,
            row.customerAccountNumber,
            null,
            row.customerBankCode as BankCode
          ),
      },
      {
        className: "table-cell",
        title: "จำนวนเงิน",
        dataIndex: "amount",
        key: "amount",
        filterSearch: true,
        width: 134,
        filters: getColumnFilters("amount", displayReportResults || []),
        onFilter: (value, row) => row.amount === value,
        render: (value) => <AmountColumn amount={value} />,
      },
      {
        className: "table-cell",
        title: "บัญชี PIGSPIN",
        dataIndex: "pigAccountName",
        key: "pigAccountName",
        render: (value, row) =>
          renderBankAccountCell(value, row.pigAccountNumber, row.bankPromptpayNo, row.pigBankCode),
      },
      {
        className: "table-cell",
        title: "ช่องทาง",
        dataIndex: "transactionChannel",
        key: "transactionChannel",
        filterSearch: true,
        filters: getColumnFilters("transactionChannel", displayReportResults || []),
        onFilter: (value, row) => row.transactionChannel?.includes(value.toString()) || false,
      },
      {
        className: "table-cell",
        title: "วันที่สร้าง",
        dataIndex: "createdDate",
        key: "createdDate",
        filterSearch: true,
        filters: getColumnFilters("createdDate", displayReportResults || []),
        onFilter: (value, row) => row.createdDate?.includes(value.toString()) || false,
      },
      {
        className: "table-cell",
        title: "เวลาสร้าง",
        dataIndex: "createdTime",
        filterSearch: true,
        width: 80,
        filters: getColumnFilters("createdTime", displayReportResults || []),
        onFilter: (value, row) => row.createdTime?.includes(value.toString()) || false,
        key: "createdTime",
      },
      {
        className: "table-cell",
        title: "หมายเหตุ",
        dataIndex: "channel",
        key: "channel",
        width: 100,
      },
    ];
    if (depositMethodFilter === BankDepositMethod.KBANK_API) {
      return [
        ...baseColumn.slice(0, 1),
        {
          className: "table-cell",
          title: "ช่องทาง",
          dataIndex: "channel",
          key: "channel",
        },
        ...baseColumn.slice(1),
      ]
    }
    return baseColumn;
  };

  const onFinish = (value: any) => {
    const newFetchFilters: UseBankReportFilters = {
      bankAccount: value?.bankAccount.accountNumber,
      startTime: value?.transferAt.startOf("day").toDate(),
      endTime: value?.transferAt.endOf("day").toDate(),
    };
    if (isEqual(fetchFilters, newFetchFilters)) {
      refetch();
    } else {
      setFetchFilters(newFetchFilters);
    }
  };

  React.useEffect(() => {
    getDepositBankList();
  }, [getDepositBankList]);

  return (
    <div className="BankReportPage">
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        initialValues={{
          transferAt: dayjs().startOf("day"),
          bankAccount: undefined,
        }}
      >
        <Row gutter={8} wrap={false} align="bottom">
          <Col flex="auto">
            <Form.Item name="bankAccount" label="บัญชี PIGSPIN">
              <BankSelect bankList={noneTrueWalletBankList} />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item name="transferAt" label="วันที่">
              <DatePicker
                allowClear
                size="large"
                showToday={false}
                value={form.getFieldValue("transferAt")}
                onChange={(date) => form.setFieldsValue({ transferAt: date })}
                onOk={(date) => form.setFieldsValue({ transferAt: date })}
                onBlur={() => setIsDateOpened(false)}
                onFocus={() => setIsDateOpened(true)}
                style={{ width: "180px" }}
                open={isDateOpened}
                renderExtraFooter={(_) => {
                  return (
                    <Row style={{ padding: "10px 0" }}>
                      <Col span={12}>
                        <Button
                          onClick={() => form.setFieldsValue({ transferAt: dayjs() })}
                          style={{ fontWeight: "bold", borderColor: colors["@cloud-6"] }}
                        >
                          วันนี้
                        </Button>
                      </Col>
                      <Col span={12} style={{ textAlign: "right" }}>
                        <Button
                          type="primary"
                          onClick={() => {
                            setIsDateOpened(false);
                            (document.activeElement as HTMLElement).blur();
                          }}
                        >
                          ตกลง
                        </Button>
                      </Col>
                    </Row>
                  );
                }}
              />
            </Form.Item>
          </Col>
          <Col>
            <Form.Item shouldUpdate>
              {() => (
                <SearchButton
                  className="search-btn"
                  size="large"
                  htmlType="submit"
                  disabled={!form.getFieldValue("transferAt") || isFetching}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <DeskPanel
        body={
          <Table
            className="main-table"
            rowKey="reportId"
            dataSource={displayReportResults}
            columns={getAllColumns()}
            loading={isFetching}
            size="small"
            bordered
          />
        }
      />
    </div>
  );
};

export default BankReportPage;
