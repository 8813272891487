import React from "react";
import { useRecoilValue } from "recoil";
import { authState } from "src/recoils";
import { CreateBankResponse, PostCreateBank } from "src/services/payment/payment";
import { toast } from "src/components";
import { BANK_TYPE } from "src/types";
import Icon from "src/components/Icon/Icon";
import Space from "src/components/Space/Space";
import { ValidateInput } from "../type";


export type BankCreateForm = {
  type: BANK_TYPE;
  bank: string;
  accountName: string;
  accountNumber: string;
  promptpay: string;
  trueKey: string;
  autowithdrawDeviceId?: string;
  autowithdrawPin?: number;
  autowithdrawMinimumAmountNoti?: number;
  limitAutoPerTransaction?: number;
};

export type CreateBankResult = {
  accountName?: ValidateInput;
  accountNumber?: ValidateInput;
  promptpay?: ValidateInput;
  trueKey?: ValidateInput;
  autowithdrawDeviceId?: ValidateInput;
  autowithdrawPin?: ValidateInput;
  autowithdrawMinimumAmountNoti?: ValidateInput;
  limitAutoPerTransaction?: ValidateInput;
}

export type BankCreateState = {
  createBank: (_bank: BankCreateForm, next?: () => void) => void;
  createBankResult?: CreateBankResult;
  setCreateBankResult: (backResult: CreateBankResult) => void;
  isCreating: boolean;
}

export const useBankCreate = (): BankCreateState => {
  const { token = '' } = useRecoilValue(authState);
  const [isCreating, setIsCreating] = React.useState<boolean>(false)
  const [createBankResult, setCreateBankResult] = React.useState<CreateBankResult>()
  const createBank = async (form: BankCreateForm, next?: () => void) => {
    setIsCreating(true)
    try {
      const response = await PostCreateBank({
        token,
        bank_type: form.type,
        bank_code: form.bank,
        bank_account_name: form.accountName,
        bank_account_number: form.accountNumber,
        phone_number: form.promptpay,
        true_auto_token: form.trueKey,
        autowithdraw_device_id: form.autowithdrawDeviceId,
        autowithdraw_pin: form.autowithdrawPin,
        autowithdraw_minimum_amount_noti: form.autowithdrawMinimumAmountNoti,
        limit_auto_per_transaction: form.limitAutoPerTransaction,
      });
      if (response.service_code === 'BOE-2000') {
        toast.success('เพิ่มบัญชีสำเร็จ')
      }
      if (next) { next(); }
    } catch (e: any) {
      const response: CreateBankResponse = e.response.data;
      if (response.service_code === 'BOE-4091') {
        setCreateBankResult({
          accountName: { validateStatus: 'error', help: (<Space><Icon name="outline-check-1" />ชื่อบัญชีไม่ถูกต้อง</Space>) },
          accountNumber: { validateStatus: 'error', help: (<Space><Icon name="outline-check-1" />เลขที่บัญชีไม่ถูกต้อง</Space>) },
        })
      } else if (response.service_code === 'BOE-4009') {
        setCreateBankResult({
          accountNumber: response.data.is_duplicate_bank ? { validateStatus: 'error', help: (<Space><Icon name="outline-check-1" />เลขที่บัญชีซ้ำในระบบ</Space>) } : undefined,
          promptpay: response.data.is_duplicate_phone ? { validateStatus: 'error', help: (<Space><Icon name="outline-check-1" />เบอร์พร้อมเพย์ซ้ำในระบบ</Space>) } : undefined,
        })
      } else {
        toast.error('เพิ่มบัญชีไม่สำเร็จ')
      }
    }
    setIsCreating(false)
  }

  return {
    createBank,
    createBankResult,
    setCreateBankResult,
    isCreating,
  }
}
