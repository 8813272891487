import { Dayjs } from "dayjs";
import { Moment } from "moment";

export enum Role {
  ADMIN = 'ADMIN',
  ADMIN_PLUS = 'ADMIN+',
  DM = 'DM',
  OWNER = 'Owner',
}

export enum Scope {
  All = 'ALL',
  DepositPage = 'DEPOSIT-PAGE',
  DepositHistoryShowAll = 'DEPOSIT-HISTORY-SHOWALL',
  CreateDepositTransaction = 'CREATE-DEPOSIT-TRANSACTION',
  EditDepositTransaction = 'EDIT-DEPOSIT-TRANSACTION',
  DeleteDepositTransaction = 'DELETE-DEPOSIT-TRANSACTION',
  CreateWithdrawTransaction = 'CREATE-WITHDRAW-TRANSACTION',
  EditWithdrawTransaction = 'EDIT-WITHDRAW-TRANSACTION',
  DeleteWithdrawTransaction = 'DELETE-WITHDRAW-TRANSACTION',
  WithdrawPage = 'WITHDRAW-PAGE',
  WithdrawHistoryShowAll = 'WITHDRAW-HISTORY-SHOWALL',
  WalletPage = 'WALLET-PAGE',
  CustomerPage = 'CUSTOMER-PAGE',
  RegisterCustomer = 'REGISTER-CUSTOMER',
  EditCustomer = 'EDIT-CUSTOMER',
  RegisterPage = 'REGISTER-PAGE',
  RegisterButton = 'REGISTER-BUTTON',
  RegisterUserOwner = 'REGISTER-USER.OWNER',
  RegisterUserDm = 'REGISTER-USER.DM',
  RegisterUserAdminPlus = 'REGISTER-USER.ADMIN+',
  RegisterUserAdmin = 'REGISTER-USER.ADMIN',
  EditUserOwner = 'EDIT-USER.OWNER',
  EditUserDm = 'EDIT-USER.DM',
  EditUserAdminPlus = 'EDIT-USER.ADMIN+',
  EditUserAdmin = 'EDIT-USER.ADMIN',
  ManageBank = 'MANAGE-BANK',
  BannerPage = 'BANNER-PAGE',
  BonusPage = 'BONUS-PAGE',
  TicketManagePage = 'TICKET-MANAGEMENT-PAGE',
  CreateBonus = 'CREATE-BONUS',
  EditBonus = 'EDIT-BONUS',
  ReportPage = 'REPORT-PAGE',
  RealtimeTransfer = 'REALTIME-TRANSFER-PAGE',
  GamePage = 'GAME-PAGE',
  PaymentSettingPage = 'PAYMENT-SETTING-PAGE',
  EditConfig = 'EDIT-CONFIG',
  AddBank = 'ADD-BANK',
  EditBank = 'EDIT-BANK',
  AffiliateOrgChartPage = 'AFFILIATE_ORGCHART-PAGE',
  AffiliateOverviewPage = 'AFFILIATE-OVERVIEW-PAGE',
  QuestManagePage = 'QUEST-MANAGEMENT-PAGE',
  SettingConfigPage = 'SETTING-CONFIG-PAGE',
}

export type ScopePermission = Partial<Record<Scope, {
  enable: boolean;
}>>

export enum BankCode {
  BAY = "BAY",
  BBL = "BBL",
  KTB = "KTB",
  KBANK = "KBANK",
  KK = "KK",
  CIMB = "CIMB",
  TTB = "TTB",
  TrueMoney = "TRUEWALLET",
  SCB = "SCB",
  BAAC = "BAAC",
  UOB = "UOB",
  LHBANK = "LHBANK",
  GSB = "GSB",
  GHBANK = "GHBANK",
  IBANK = "IBANK",
  NONE = "NONE",
}

export enum BANK_NAME {
  KBANK = "กสิกรไทย",
  SCB = "ไทยพาณิชย์",
  KTB = "กรุงไทย",
  BBL = "กรุงเทพ",
  BAY = "กรุงศรีอยุธยา",
  LHBANK = "แลนด์ แอนด์ เฮ้าส์",
  IBANK = "อิสลามแห่งประเทศไทย",
  UOB = "ยูโอบี",
  GSB = "ออมสิน",
  BAAC = "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
  CIMB = "ซีไอเอ็มบี ไทย",
  KK = "เกียรตินาคิน",
  GHBANK = "อาคารสงเคราะห์",
  TTB = "ทีเอ็มบีธนชาต",
  TMW = "TMW",
  TrueMoney = "ทรูมันนี่ วอลเล็ต",
  NONE = "none",
}

export type BankAccountStatus = "Active" | "OverTransaction" | "Terminated" | "Banned" | "Closed"

export interface BankAccount {
  bankCode: BankCode
  bankName: string
  accountName: string
  accountNameEN?: string
  accountNumber: string
  default_bgb_deposit_bank_code?: string;
  default_group_bank_code?: string;
}

export interface Upline {
  player_username: string
}

export type BonusType = 'DEPOSIT' | 'CASHBACK'

export interface PromotionState {
  status: boolean | string
  type?: BonusType
  lastUpdate?: Date
  latestToggleAt?: Date
  latestTypeToggleAt?: Date
}

export interface Response<T = any> {
  status: string
  code: number
  serviceCode: string
  serviceMessage: string
  data: T
}

export interface DepositTransactions {
  total_page: number
  offset: number
  limit: number
  total_transactions: number
  results: {
    fromBankCode: BankCode
    fromBankAccountName: string
    fromBankAccountNumber: string
    toBankCode: BankCode
    toBankAccountName: string
    toBankAccountNumber: string
    transferredAt: string
    amount: number
  }[]
}

export enum BANK_TYPE {
  DEPOSIT = "DEPOSIT",
  WITHDRAW = "WITHDRAW",
  NONE = 'NONE',
}

export type GroupBank = {
  groupBankId: string;
  groupBankName?: string;
  bankCode: BankCode;
}

export type BankRow = BankAccount & GroupBank & {
  key?: string;
  bankId: string;
  promptpay: string;
  trueKey: string;
  forcePending?: Moment;
  isAuto: boolean;
  isWebAuto?: boolean;
  isApi?: boolean;
  isSms?: boolean;
  status: BankAccountStatus;
  type: BANK_TYPE;
  verifyAt?: Date;
  limitAutoPerTransaction?: number;

  // Realtime transfer
  balance?: number;
  bankBalance?: number;
  limitTransfer?: number;
  availableBalance?: number;
  transferedAmount?: number;

  //Autowithdraw
  autowithdrawDeviceId?: string;
  autowithdrawPin?: number;
  autowithdrawMinimumAmountNoti?: number;
}

export enum CustomerStatus {
  ACTIVE = "ACTIVE",
  DISABLED = "DISABLED",
  BLOCKED = "BLOCKED",
}

export type CustomerRow = {
  customerId: string
  pin: string
  name: string
  customerCode: string
  phone: string
  bank: BankAccount
  upline: Upline
  promotionState: PromotionState
  trueWalletId: string
  isLineLinked: boolean
  status: CustomerStatus
  statusText: string;
  hasKyc: boolean;
  firstName: string;
  lastName: string;
  createdAt?: Dayjs;
  referrer?: string;
  lineUserId?: string;
  playerNickname?: string;
}

export enum BankDepositMethod {
  SCB_SMS = "SCB SMS",
  KBANK_SMS = "KBANK SMS",
  SCB_API = "SCB API",
  KBANK_API = "KBANK API",
}

export * from "./api"

export interface PubnubTokenStatus {
  isBan: boolean;
  rainBanned?: boolean;
  startBanDate: string | null;
  endBanDate: string | null;
  isWrite: boolean;
  turnover: number;
  totalTurnover: number;
}
