import * as React from 'react';
import NiceModal from '@ebay/nice-modal-react';
import { Space, Spin, Typography, Radio } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import dayjs from 'dayjs';
import isEqual from "react-fast-compare";
import PageHeader from '../../components/PageHeader/PageHeader';
import RadioButton from '../../components/RadioButton/RadioButton';
import Table from '../../components/Table/Table';
import { FILTER, Transaction, TRANSFER_TYPE, WITHDRAW_STATUS } from '../../hooks/useTransaction/type';
import { useTransactionState, } from '../../hooks/useTransaction/useTransaction';
import WithdrawModal from '../../components/Modals/WithdrawModal/WithdrawModal';
import { COOKIES_KEY, useCookies } from '../../hooks/useCookies';
import RefundModal from '../../components/Modals/RefundModal/RefundModal';
import { AddButton, BankLogo, Icon, Tabs } from "src/components"
import { AlertNiceModal, ManualWithdrawCreate } from 'src/components/Modals';
import ManualWithdrawEdit from 'src/components/Modals/ManualWithdrawModal/ManualWithdrawEdit';
import { useRecoilState, useRecoilValue } from 'recoil';
import { affiliateStateAtom, authInfoState } from 'src/recoils';
import { GroupBank, Scope } from 'src/types';
import { getCallConfig } from "../../hooks/useTransaction/config";
import { mapToTransaction } from "../../mapper/mapToTransaction";
import { WEBSOCKET_URL } from "../../models/buildtime-constant";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";
import { Menu, MenuItem } from "../../components/Menu/Menu";
import { ActionColumn } from 'src/hooks/useTransaction/renderColumn';
import { reserveWithdrawTransaction } from 'src/services/payment/payment';
import { useBank } from 'src/hooks/useTransaction/useBank';
import { datetime } from 'src/utils';

interface IDepositProps {}

let cachingData: any[] = [];

export const Transfer: React.FunctionComponent<IDepositProps> = (props) => {
  const [cookies, setCookies] = useCookies([]);
  const { token, scope, userId } = useRecoilValue(authInfoState);
  const [affiliateState, setAffiliateState] = useRecoilState(affiliateStateAtom);
  const [creatingManualWithdraw, setCreatingManualWithdraw] = React.useState<boolean>(false)
  const { autoWithdrawGroupBank } = useBank();
  const [transferType, setTransferType] = React.useState<TRANSFER_TYPE>(TRANSFER_TYPE.NORMAL);
  const transferState = useTransactionState({ filter: FILTER.PENDING_WITHDRAW });
  const {
    getSelectedTab,
    getFilterOptions,
    getAllColumn,
    getBaseTableProps,
    searchCriteria,
    setSearchCriteria,
    doCallWithdrawTransaction,
    editingManualWithdraw,
    reserveTransaction,
    cancelReserveTransaction,
    setTransactions,
    setRetryAutoWithdrawingTransaction,
    searchResult,
    doCallRetryAutoWithdraw,
    setSearchResult,
    showDoneTransactionModal,
  } = transferState;

  React.useEffect(() => {
    doCallWithdrawTransaction(searchCriteria);

    if (getSelectedTab() !== FILTER.ONLY_ME_WITHDRAW) {
      const websocketURL = WEBSOCKET_URL
      const ws = new WebSocket(websocketURL)
      let sendFilterInterval: NodeJS.Timeout;

      ws.onopen = (event) => {
        console.log("Websocket connected.")
        sendFilterInterval = setInterval(() => {
          const { directCall, otherTabCall } = getCallConfig(token, searchCriteria, affiliateState);
          const primaryFilters = {
            ...snakecaseKeys(directCall.payload, { deep: true })
          }
          const secondaryFilters = {
            ...snakecaseKeys(otherTabCall.payload, { deep: true })
          }
          const allFilters = snakecaseKeys({
            primaryFilters,
            secondaryFilters,
            userId
          }, { deep: true })
          ws.send(JSON.stringify(allFilters))
        }, 1000)
      }

      ws.onmessage = function (event) {
        const json = JSON.parse(event.data)
        const primaryData = json.primary_data_query.data
        const primaryDataCache = primaryData.results;
        if (primaryDataCache.length > 0 && primaryDataCache[0].type === 'DEPOSIT') { return; }
        //const shouldPreventExpensiveRender = isEqual(cachingData, primaryDataCache);
        //if (shouldPreventExpensiveRender) { return; }
        //cachingData = primaryDataCache
        const secondaryData = json.secondary_data_query.data
        setTransactions(primaryData.results.map((_transaction: any) => mapToTransaction(camelcaseKeys(_transaction, { deep: true }) as any)));
        setSearchResult({
          ...searchResult,
          totalTransaction: primaryData.total,
          totalOtherTransaction: secondaryData.total,
          limit: primaryData.page_size,
          offset: primaryData.page,
          totalPage: primaryData.total / primaryData.page_size,
        })
      }

      ws.onclose = () => {
        clearInterval(sendFilterInterval)
        console.log("Websocket disconnected.")
      }

      return () => { clearInterval(sendFilterInterval); ws.close() }
    }
  }, [searchCriteria, affiliateState.isAffiliateMode]);

  const onFilter = (filter: FILTER) => {
    setSearchCriteria({
      ...searchCriteria,
      offset: 1,
      filter,
    })
    setCookies(COOKIES_KEY.WITHDRAW_SELECTED_TAB, filter);
  };

  const onCloseEditModal = () => {
    if (editingManualWithdraw) {
      cancelReserveTransaction(editingManualWithdraw, 'EDIT_WITHDRAW');
    }
    doCallWithdrawTransaction(searchCriteria);
  }

  const onCloseCreateModal = () => {
    setCreatingManualWithdraw(false)
    doCallWithdrawTransaction(searchCriteria);
  }

  const onAutoWithdraw = async (row: Transaction, groupBank: GroupBank) => {
    setRetryAutoWithdrawingTransaction(row);
    try {
      const response = await reserveWithdrawTransaction({
        token,
        transaction_uid: row.transactionId,
      });
      if (response.status === 'SUCCESS') {
        NiceModal.show(AlertNiceModal, {
          type: 'warning',
          isModalVisible: true,
          title: (
            <Space>
              <div>โอนเงินด้วยระบบอัตโนมัติ</div>
              <BankLogo bankCode={groupBank.bankCode} />
            </Space>
          ),
          content: 'ต้องการโอนเงินด้วยระบบอัตโนมัติใช่หรือไม่',
          buttonType: 'question',
          onCancel: () => { cancelReserveTransaction(row, undefined, true); },
          onConfirm: () => { doCallRetryAutoWithdraw(row, groupBank); },
        });
      }
    } catch (e: any) {
      showDoneTransactionModal(row);
    }
  };

  const getPendingWithdrawTransactionColumn = React.useMemo((): ColumnsType<Transaction> => {
    const allColumn = getAllColumn();
    const isWaitingTransfer = searchCriteria.filter === FILTER.REFUND
    const dateTimeColumn = isWaitingTransfer ? allColumn.updatedAt : allColumn.createdAt;
    const baseColumns: ColumnsType<Transaction> = [
      {
        ...dateTimeColumn,
        title: 'วันที่',
        width: 98,
        render: (dateTime: string) => dayjs(dateTime).isValid() ? datetime(dateTime).format('DD/MM/YYYY') : '',
      },
      {
        ...dateTimeColumn,
        title: 'เวลาสร้าง',
        width: 80,
        render: (dateTime: string) => dayjs(dateTime).isValid() ? datetime(dateTime).format('HH:mm') : '',
      },
      allColumn.customerAccount,
      allColumn.userAccount,
      allColumn.amount,
      allColumn.status,
      allColumn.remark,
      allColumn.moreDetail,
      allColumn.cancelReserve,
      {
        title: '',
        dataIndex: 'transfer',
        key: 'transfer',
        fixed: 'right',
        width: 120,
        render: (value: Date, row: Transaction) => {
          if (row.isSending) {
            return <Space><Spin /><span>Processing..</span></Space>
          }
          if (searchCriteria.filter === FILTER.REFUND) {
            return (<ActionColumn {...row} action={() => { reserveTransaction(row) }} />)
          }

          const menu = (
            <Menu>
              {autoWithdrawGroupBank.map((groupBank) => {
                return (
                  <MenuItem
                    key={groupBank.groupBankId}
                    disabled={searchCriteria.filter !== FILTER.PENDING_WITHDRAW || row.status !== WITHDRAW_STATUS.PENDING}
                    onClick={() => onAutoWithdraw(row, groupBank)}>
                    <Space align="center">
                      <Typography.Text style={{ fontSize: '1rem' }}  >
                        <Icon name="outline-transfer-1" size="1.5rem" />
                        &nbsp;
                        ด้วยระบบอัตโนมัติ
                      </Typography.Text>
                      <BankLogo width={25} height={25} bankCode={groupBank.bankCode} />
                    </Space>
                  </MenuItem>
                )
              })}
              <MenuItem
                key="viabankapp"
                style={{ padding: '1rem' }} onClick={() => { reserveTransaction(row, WITHDRAW_STATUS.PENDING) }}>
                <Typography.Text style={{ fontSize: '1rem' }} >
                  <Icon name="outline-mobile-1" size="1.5rem" />
                  &nbsp;
                  ผ่านแอปธนาคาร
                </Typography.Text>
              </MenuItem>
            </Menu>
          );

          return (
            <div style={{ textAlign: 'right' }}>
              <Space>
                <ActionColumn
                  {...row}
                  dropdownChildren={menu}
                />
              </Space>
            </div>
          )
        },
      }
    ];
    if (isWaitingTransfer) {
      return [
        ...baseColumns.slice(0, 1),
        allColumn.transferedAt,
        ...baseColumns.slice(1),
      ]
    }
    return baseColumns;
  }, [autoWithdrawGroupBank])

  const getHistoryWithdrawTransactionColumn = () => {
    const allColumn = getAllColumn();
    return [
      {
        ...allColumn.updatedAt,
        title: 'วันที่',
        width: 98,
        render: (dateTime: string) => dayjs(dateTime).isValid() ? dayjs(dateTime).format('DD/MM/YYYY') : '',
      },
      {
        ...allColumn.updatedAt,
        title: 'เวลาสร้าง',
        width: 80,
        render: (dateTime: string) => dayjs(dateTime).isValid() ? dayjs(dateTime).format('HH:mm') : '',
      },
      allColumn.adminAccount,
      allColumn.customerAccount,
      allColumn.userAccount,
      allColumn.amount,
      allColumn.status,
      allColumn.remark,
      allColumn.moreDetail,
    ];
  }

  return (
    <>
      <Radio.Group
        onChange={(e) => {
          const selectingTab = e.target.value as TRANSFER_TYPE
          setTransferType(selectingTab);
          setAffiliateState({
            isAffiliateMode: selectingTab === TRANSFER_TYPE.AFFILIATE,
          });
        }}
        value={transferType}
        style={{ marginBottom: 8 }}
        buttonStyle="solid"
        size="large"
      >
        <Radio.Button style={{ width: 124, textAlign: 'center' }} value={TRANSFER_TYPE.NORMAL}>ทั่วไป</Radio.Button>
        <Radio.Button style={{ width: 124, textAlign: 'center' }} value={TRANSFER_TYPE.AFFILIATE}>แนะนำเพื่อน</Radio.Button>
      </Radio.Group>
      <PageHeader title={(
        <Tabs
          defaultActiveKey={getSelectedTab()}
          onChange={(_tab) => {
            onFilter(_tab as FILTER);
          }}>
          <Tabs.TabPane tab="รายการที่ต้องทำ" key={FILTER.PENDING_WITHDRAW} />
          <Tabs.TabPane tab="ดำเนินรายการ" key={FILTER.WITHDRAW_PROCESSING} />
          {/* 
          TODO : make sure that we dont call all history
          <Tabs.TabPane tab="ประวัติทำรายการ" key={FILTER.ONLY_ME_WITHDRAW} /> */}
        </Tabs>
      )}
        extra={scope[Scope.CreateWithdrawTransaction]?.enable && ([
          <AddButton
            key="add"
            onClick={() => {
              setCreatingManualWithdraw(true)
            }}
          >
            เพิ่มรายการถอน
          </AddButton>,
        ])}
      />
      {(getSelectedTab() === FILTER.PENDING_WITHDRAW ||
        getSelectedTab() === FILTER.WITHDRAW_PROCESSING ||
        getSelectedTab() === FILTER.REFUND) && (
          <>
            <RadioButton
              value={searchCriteria.filter}
              options={getFilterOptions()}
              onChange={(e) => {
                onFilter(e.target.value);
              }}
            />
            <br />
            <br />
            <Table
              {...getBaseTableProps()}
              columns={getPendingWithdrawTransactionColumn}
            />
          </>
        )}
      {/* 
      TODO : make sure that we dont call all history
      {(getSelectedTab() === FILTER.ONLY_ME_WITHDRAW) && (<>
        <RadioButton
          value={searchCriteria.filter}
          options={getFilterOptions()}
          onChange={(e) => {
            onFilter(e.target.value);
          }}
        />
        <br />
        <br />
        <Table
          {...getBaseTableProps()}
          columns={getHistoryWithdrawTransactionColumn()}
        />
      </>)} */}
      <WithdrawModal {...transferState} />
      <RefundModal {...transferState} />
      <ManualWithdrawCreate visible={creatingManualWithdraw} onSuccess={onCloseCreateModal} onCancel={onCloseCreateModal} />
      <ManualWithdrawEdit editingTransaction={editingManualWithdraw} onSuccess={onCloseEditModal} onCancel={onCloseEditModal} />
    </>
  );
};

export default Transfer;
