import { Moment } from "moment";
import React from "react";
import { useRecoilValue } from "recoil";
import { toast } from "src/components";
import Icon from "src/components/Icon/Icon";
import Space from "src/components/Space/Space";
import { authState } from "src/recoils";
import { DepositBankRequest, EditBankRequest, EditBankResponse, PutEditBank, WithdrawBankRequest } from "src/services/payment/payment";
import { BankAccountStatus, BankRow, BANK_TYPE } from "src/types";
import { ValidateInput } from "./type";

export type EditBankResult = {
  status?: ValidateInput;
  promptpay?: ValidateInput;
  trueKey?: ValidateInput;
  autowithdraw_device_id?: ValidateInput;
  autowithdraw_pin?: ValidateInput;
  autowithdraw_minimum_amount_noti?: ValidateInput;
  limit_transfer?: ValidateInput;
}

export type EditForm = {
  bankId: string;
  promptpay: string;
  trueKey: string;
  status: BankAccountStatus;
  type: BANK_TYPE;
  isAuto: boolean;
  isWebAuto?: boolean;
  forcePending?: Moment;
  isApi?: boolean;
  isSms?: boolean;
  autowithdrawDeviceId?: string;
  autowithdrawPin?: number;
  autowithdrawMinimumAmountNoti?: number;
  limitAutoPerTransaction?: number;
};

export type CallBackEditBank = {
  onSuccess: () => void;
  onError: () => void;
}

export type BankEditState = {
  editBank: (_bank: EditForm, callback?: CallBackEditBank) => void;
  editBankResult?: EditBankResult;
  setEditBankResult: (editBankResult: EditBankResult) => void;
  isEditing: boolean;
}

export const useBankEdit = (): BankEditState => {
  const { token = '' } = useRecoilValue(authState);
  const [editBankResult, setEditBankResult] = React.useState<EditBankResult>();
  const [isEditing, setIsEditing] = React.useState<boolean>(false);
  const editBank = async (form: EditForm, callback?: CallBackEditBank) => {
    setIsEditing(true);
    try {
      let request: EditBankRequest = {
        token,
        bank_type: form.type,
        bgb_bank_code_uid: form.bankId,
        status: form.status,
        phone_number: form.promptpay,
        true_auto_token: form.trueKey,
        force_pending_to: form.forcePending?.utc().toDate(),
        autowithdraw_device_id: form.autowithdrawDeviceId,
        autowithdraw_pin: form.autowithdrawPin,
        autowithdraw_minimum_amount_noti: form.autowithdrawMinimumAmountNoti,
        limit_auto_per_transaction: form.limitAutoPerTransaction
      }
      if (form.type === BANK_TYPE.DEPOSIT) {
        request = {
          ...request,
          is_auto: form.isAuto,
          is_api: form.isApi || false,
          is_sms: form.isSms || false,
        } as DepositBankRequest;
      }
      if (form.type === BANK_TYPE.WITHDRAW) {
        request = {
          ...request,
          is_auto_bo: form.isAuto,
          is_auto_web: form.isWebAuto,
        } as WithdrawBankRequest;
      }
      const response = await PutEditBank(request);
      if (callback?.onSuccess) callback?.onSuccess();
    } catch (e: any) {
      const response: EditBankResponse = e.response.data;
      const isDuplicatePromptpay = response.service_code === 'BOE-4009';
      if (isDuplicatePromptpay) {
        setEditBankResult({
          promptpay: isDuplicatePromptpay ? { validateStatus: 'error', help: (<Space><Icon name="outline-check-1" />เบอร์พร้อมเพย์ซ้ำในระบบ</Space>) } : undefined,
        })
      } else {
        if (callback?.onError) callback?.onError();
      }
    }
    setIsEditing(false);
  };

  return {
    editBank,
    editBankResult,
    setEditBankResult,
    isEditing,
  }
}
