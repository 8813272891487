import { Col, DatePicker, Dropdown, Form, Input, Radio, Row, Space, Switch, Typography } from 'antd';
import moment from 'moment';
import * as React from 'react';
import { useRecoilState } from 'recoil';
import { BankEditState, EditBankResult, EditForm } from 'src/hooks/useBankEdit';
import { mapToBankTypeText } from 'src/mapper/mapToBank';
import { modalState } from 'src/recoils';
import { BankRow, BankAccountStatus, BankCode, BANK_TYPE } from 'src/types';
import { getBankFormat, getPhoneFormat } from 'src/utils/textOperation/textOperation';
import { BankLogo } from '..';
import Status from '../Bank';
import Button from '../Button/Button';
import Icon from '../Icon/Icon';
import { Menu, MenuItem } from '../Menu/Menu';
import Modal from '../Modal/Modal';
import './EditBankForm.less';

export type IEditBankFormProps = {
  editBankState: BankEditState;
  editingBank?: BankRow;
  onCancel: () => void;
  onEditConfirm: (form: EditForm, successText?: string, errorText?: string) => void;
};


const EditBankForm: React.FunctionComponent<IEditBankFormProps> = (props) => {
  const [, setModal] = useRecoilState(modalState);
  const { editBankState, editingBank, onCancel, onEditConfirm } = props;
  const { editBankResult, setEditBankResult } = editBankState;
  const [form] = Form.useForm<EditForm>()
  const [disableEditButton, setDisableEditButton] = React.useState<boolean>(true);
  const [disableForcePending, setDisableForcePending] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (editingBank) {
      form.setFieldsValue({
        type: editingBank.type,
        status: editingBank.status,
        promptpay: editingBank.promptpay,
        trueKey: editingBank.trueKey,
        isAuto: editingBank.isAuto,
        isWebAuto: editingBank.isWebAuto,
        forcePending: editingBank.forcePending,
        isApi: editingBank.isApi,
        isSms: editingBank.isSms,
      });
      setDisableEditButton(true)
      setDisableForcePending(!editingBank.isAuto);
    } else {
      form.resetFields()
      setEditBankResult({})
    }
  }, [editingBank]);

  const checkDisableEditButton = (editForm: EditForm) => {
    if (!editingBank) return;
    const baseCheckingField =
      editForm.status === editingBank.status &&
      editForm.isAuto === editingBank.isAuto &&
      moment(editForm.forcePending).isSame(moment(editingBank.forcePending));

    if (editingBank?.type === BANK_TYPE.DEPOSIT) {
      const depositFormDoesNotChange = baseCheckingField &&
        editForm.promptpay === editingBank.promptpay &&
        editForm.isApi === editingBank.isApi &&
        editForm.isSms === editingBank.isSms
      setDisableEditButton(depositFormDoesNotChange)
    }

    if (editingBank?.type === BANK_TYPE.WITHDRAW) {
      const withdrawFormDoesNotChange = baseCheckingField && editForm.isWebAuto === editingBank.isWebAuto;
      setDisableEditButton(withdrawFormDoesNotChange)
    }
  }

  const onEdit = () => {
    const editingForm = form.getFieldsValue();
    if (editingBank) {
      const isStatusChanged = editingForm.status !== editingBank.status;
      let isAuto = editingForm.isAuto;
      let isWebAuto = editingForm.isWebAuto;
      if (isStatusChanged) {
        if (editingForm.status === 'Active') {
          isAuto = true;
          isWebAuto = true;
        }
        if (editingForm.status === 'Closed') {
          isAuto = false;
          isWebAuto = false;

        }
      }
      setModal({
        isModalVisible: true,
        type: 'warning',
        title: 'แก้ไขบัญชี',
        content: 'ต้องการแก้ไขบัญชีนี้ใช่หรือไม่',
        buttonType: 'question',
        onConfirm: () => {
          onEditConfirm({
            ...editingForm,
            bankId: editingBank.bankId,
            type: editingBank.type,
            isAuto,
            isWebAuto,
          }, 'แก้ไขสำเร็จ', 'แก้ไขไม่สำเร็จ')
        },
      });
    }
  }

  const onInactiveBank = (status: BankAccountStatus) => {
    const editingForm = form.getFieldsValue();
    if (editingBank) {
      setModal({
        isModalVisible: true,
        type: 'warning',
        title: 'ปิดบัญชี',
        content: 'ต้องการปิดบัญชีนี้ใช่หรือไม่',
        buttonType: 'question',
        onConfirm: () => {
          onEditConfirm({
            bankId: editingBank.bankId,
            promptpay: editingForm.promptpay,
            trueKey: editingForm.trueKey,
            type: editingBank.type,
            isAuto: false,
            isWebAuto: false,
            status: status,
          }, 'ปิดบัญชีสำเร็จ', 'ปิดบัญชีไม่สำเร็จ')
        },
      });
    }
  }

  const formatAccount = editingBank?.bankCode === BankCode.TrueMoney ? getPhoneFormat : getBankFormat

  return (
    <Modal
      centered
      className="EditBankForm"
      visible={editingBank !== undefined}
      title="แก้ไขข้อมูลบัญชี"
      footer={editingBank && (
        <>
          <Button size='large' style={{ width: 100 }} onClick={() => { onCancel() }}>
            ยกเลิก
          </Button>
          <Button
            disabled={disableEditButton}
            htmlType='submit'
            size='large'
            type='primary'
            style={{ width: 180 }}
            onClick={() => { onEdit() }}
          >
            บันทึก
          </Button>
        </>
      )}
    >
      {editingBank && (
        <>
          <div className='EditBankForm_Heading'>
            <div><Space><Typography.Text strong>ประเภท:</Typography.Text><Typography>{mapToBankTypeText(editingBank.type)}</Typography></Space></div>
            <div><Space><Typography.Text strong>ธนาคาร:</Typography.Text><BankLogo bankCode={editingBank.bankCode} />{editingBank.bankName}</Space></div>
            <div><Space><Typography.Text strong>ชื่อบัญชี:</Typography.Text><Typography>{editingBank.accountName}</Typography></Space></div>
            <div><Space><Typography.Text strong>เลขที่บัญชี:</Typography.Text><Typography>{formatAccount(editingBank.accountNumber)}</Typography></Space></div>
            {formatAccount(editingBank.trueKey) && <div><Space><Typography.Text strong>True KEY:</Typography.Text><Typography>{editingBank.trueKey}</Typography></Space></div>}
            <Dropdown
              arrow
              placement="bottomRight"
              overlay={(
                <Menu>
                  <MenuItem key="Banned" onClick={(e) => { onInactiveBank(e.keyPath[0] as BankAccountStatus) }}>
                    <Icon name='outline-notbank-2' size='22px' />&nbsp;ถูกธนาคารระงับใช้งาน
                  </MenuItem>
                  <MenuItem key="Terminated" onClick={(e) => { onInactiveBank(e.keyPath[0] as BankAccountStatus) }}>
                    <Icon name='outline-notother-2' size='22px' />&nbsp;สาเหตุอื่นๆ
                  </MenuItem>
                </Menu>
              )}>
              <Button shape="round" style={{ position: 'absolute', top: '18px', right: '24px' }}>ปิดบัญชี</Button>
            </Dropdown>
          </div>
          <div className='EditBankForm_BodyForm'>
            <Form
              form={form}
              name="editBankForm"
              layout="vertical"
              onValuesChange={(changedValues, _form) => {
                const isSwitchingAuto = changedValues.isAuto !== undefined;
                if (isSwitchingAuto) {
                  form.setFieldsValue({ forcePending: undefined });
                }
                checkDisableEditButton(_form);
                setDisableForcePending(!_form.isAuto);
              }}
            >
              <Row>
                <Col span={24}>
                  <Typography.Text strong className="text-cloud-7">ระบบอัตโนมัติ</Typography.Text>
                </Col>
                <Col span={12}>
                  <Space align="baseline">
                    <Form.Item label="" valuePropName="checked" name="isAuto">
                      <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
                    </Form.Item>
                    ระบบหลังบ้าน
                  </Space>
                </Col>
                <Col span={12}>
                  {editingBank.type === BANK_TYPE.WITHDRAW && (
                    <Space align="baseline">
                      <Form.Item label="" valuePropName="checked" name="isWebAuto">
                        <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
                      </Form.Item>
                      หน้าเว็บไซต์
                    </Space>
                  )}
                </Col>
                <Col span={24}>
                  <Form.Item label="ตั้งเวลาปิด" name="forcePending">
                    <DatePicker showTime size='large' disabled={disableForcePending} />
                  </Form.Item>
                </Col>
                {editingBank.type === BANK_TYPE.DEPOSIT && editingBank.bankCode !== BankCode.TrueMoney && (
                  <>
                    <Col span={24}>
                      <Typography.Text strong className="text-cloud-7">ระบบ scraping</Typography.Text>
                    </Col>
                    <Col span={12}>
                      <Space align="baseline">
                        <Form.Item label="" valuePropName="checked" name="isSms">
                          <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
                        </Form.Item>
                        SMS
                      </Space>
                    </Col>
                    <Col span={12}>
                      <Space align="baseline">
                        <Form.Item label="" valuePropName="checked" name="isApi">
                          <Switch checkedChildren="เปิด" unCheckedChildren="ปิด" />
                        </Form.Item>
                        API
                      </Space>
                    </Col>
                  </>
                )}
                {!(editingBank.bankCode === BankCode.TrueMoney || editingBank.type === BANK_TYPE.WITHDRAW) && (
                  <Col span={24}>
                    <Form.Item label="เบอร์พร้อมเพย์" name="promptpay"
                      validateStatus={editBankResult?.promptpay?.validateStatus}
                      help={editBankResult?.promptpay?.help}
                      rules={[
                        { pattern: new RegExp(/(0){1}(\d{9})/), message: 'รูปแบบไม่ถูกต้อง' },
                      ]}
                    >
                      <Input placeholder="ใส่เบอร์พร้อมเพย์" size='large' maxLength={10} />
                    </Form.Item>
                  </Col>
                )}
                {(editingBank.bankCode === BankCode.TrueMoney) && (
                  <Col span={24}>
                    <Form.Item
                      label="key สำหรับ TRUE อัตโนมัติ"
                      name="trueKey"
                    >
                      <Input placeholder='ใส่ key สำหรับ TRUE อัตโนมัติ (ถ้ามี)' size='large' maxLength={255} />
                    </Form.Item>
                  </Col>
                )}
                <Col span={24}>
                  <Form.Item label="สถานะ" name="status">
                    <Radio.Group>
                      <Space direction="vertical">
                        <Radio value={'Active'}><Status type={'Active'} /></Radio>
                        <Radio value={'Closed'}><Status type={'Closed'} /></Radio>
                        <Radio value={'OverTransaction'} disabled><Status type={'OverTransaction'} /></Radio>
                      </Space>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </div>
        </>
      )}
    </Modal>
  );
};

export default EditBankForm;
