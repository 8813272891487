import NiceModal from "@ebay/nice-modal-react";
import { Col, Divider, Dropdown, Form, Input, Menu, Radio, Row, Select, Space, Typography } from 'antd';
import dayjs from 'dayjs';
import * as React from 'react';
import { AmountColumn, amountValidatorAllowZero, Button, ColumnType, DatePicker, DateTime, Icon, MoneyInput, PageHeader, SELECTION_COLUMN, Tabs, toast, ViewMoreButton } from 'src/components';
import Account from 'src/components/Bank/Account';
import { BankSelect } from 'src/components/Bank/BankSelect';
import DeskPanel from 'src/components/DeskPanel/DesktPanel';
import Table from 'src/components/Table';
import { BankColumns } from 'src/components/TableColumns/BankColumn';
import { ADJUST_BALANCE_TYPE, CHANNEL, useRealtimeTransfer } from 'src/hooks/useRealtimeTransfer';
import { HitorySearchCriteria, RealtimeTransferHistoryItem, STATUS, useRealtimeTransferHistory } from 'src/hooks/useRealtimeTransferHistory';
import { BankAccount, BankCode, BankRow, BANK_TYPE } from 'src/types';
import { numberWithoutCommas } from 'src/utils/number/number';
import colors from 'src/theme/colors.json';
import './RealtimeTransfer.less';
import RealtimeTransferViewMoreModal from "src/components/Modals/RealtimeTransferViewMoreModal/RealtimeTransferViewMoreModal";
import Modal from "src/components/Modal/Modal";
import { OutlineEdit1 } from "src/icons";
import { useRecoilState } from "recoil";
import { modalState } from "src/recoils";
import { RadioGroup } from "src/components/RadioButton/RadioButton";
import { datetime } from "src/utils";

interface IRealtimeTransferProps {
}

export enum TAB {
  BALANCE = 'BALANCE',
  LIMIT_TRANSFER = 'LIMIT_TRANSFER',
  HISTORY = 'HISTORY',

  NONE = 'NONE',
};

export const formConfig = {
  [ADJUST_BALANCE_TYPE.INTERNAL_TRANSFER]: {
    withdrawBankList: true,
    depositBankList: true,
    amount: true,
    fee: true,
    datetime: true,
    channel: true,
    remark: true,
  },
  [ADJUST_BALANCE_TYPE.DEPOSIT]: {
    withdrawBankList: false,
    depositBankList: true,
    amount: true,
    fee: true,
    datetime: true,
    channel: true,
    remark: true,
  },
  [ADJUST_BALANCE_TYPE.WITHDRAW]: {
    withdrawBankList: true,
    depositBankList: false,
    amount: true,
    fee: true,
    datetime: true,
    channel: true,
    remark: true,
  },
  [ADJUST_BALANCE_TYPE.DIF_MINUS]: {
    withdrawBankList: true,
    depositBankList: false,
    amount: true,
    fee: true,
    datetime: true,
    channel: true,
    remark: true,
  },
  [ADJUST_BALANCE_TYPE.DIF_PLUS]: {
    withdrawBankList: false,
    depositBankList: true,
    amount: true,
    fee: true,
    datetime: true,
    channel: true,
    remark: true,
  },
  [ADJUST_BALANCE_TYPE.FEE]: {
    withdrawBankList: true,
    depositBankList: false,
    amount: true,
    fee: true,
    datetime: true,
    channel: true,
    remark: true,
  },
}

export type AdjustBalanceForm = {
  amount: number;
  channel: string;
  datetime: dayjs.Dayjs;
  depositBankList: BankRow;
  withdrawBankList: BankRow;
  fee: number;
  remark: string;
};

const adjustTypeConfig = {
  ['ALL']: {
    searchCriteria: {
      channel: CHANNEL.ALL,
    },
  },
  [ADJUST_BALANCE_TYPE.INTERNAL_TRANSFER]: {
    text: 'โอนย้ายเงินในระบบ',
    color: colors['@blue-6'],
    searchCriteria: {
      channel: CHANNEL.ALL,
    },
  },
  [ADJUST_BALANCE_TYPE.DEPOSIT]: {
    text: 'นำเงินเข้าระบบ',
    color: colors['@green-7'],
    searchCriteria: {
      channel: CHANNEL.ALL,
    },
  },
  [ADJUST_BALANCE_TYPE.WITHDRAW]: {
    text: 'นำเงินออกจากระบบ',
    color: colors['@red-6'],
    searchCriteria: {
      channel: CHANNEL.ALL,
    },
  },
  [ADJUST_BALANCE_TYPE.DIF_PLUS]: {
    text: 'Dif+',
    color: colors['@green-7'],
    searchCriteria: {
      channel: CHANNEL.ALL,
    },
  },
  [ADJUST_BALANCE_TYPE.DIF_MINUS]: {
    text: 'Dif-',
    color: colors['@red-6'],
    searchCriteria: {
      channel: CHANNEL.ALL,
    },
  },
  [CHANNEL.FEE]: {
    text: 'หักค่าธรรมเนียม',
    color: colors['@red-6'],
    searchCriteria: {
      adjustType: ADJUST_BALANCE_TYPE.DIF_MINUS,
      channel: CHANNEL.FEE,
    }
  }
};

const channelConfig = {
  [CHANNEL.ALL]: { value: CHANNEL.ALL, label: 'ALL' },
  [CHANNEL.ATM]: { value: CHANNEL.ATM, label: 'ATM' },
  [CHANNEL.FEE]: { value: CHANNEL.FEE, label: 'FEE' },
  [CHANNEL.IBANK]: { value: CHANNEL.IBANK, label: 'Internet Banking' },
  [CHANNEL.MBANK]: { value: CHANNEL.MBANK, label: 'Mobile Banking' },
  [CHANNEL.BRANCH]: { value: CHANNEL.BRANCH, label: 'ที่สาขาธนาคาร' },
};

const channelOptions = [
  channelConfig[CHANNEL.ATM],
  channelConfig[CHANNEL.IBANK],
  channelConfig[CHANNEL.MBANK],
  channelConfig[CHANNEL.BRANCH],
];

const DEPOSIT_BANK_HEADER: BankRow = {
  bankCode: BankCode.NONE,
  groupBankId: '',
  accountName: 'บัญชีรับเงิน',
  accountNumber: '',
  bankBalance: 0,
  balance: 0,
  bankId: '',
  bankName: 'DepositBank', // REMIND : class name
  promptpay: '',
  trueKey: '',
  type: BANK_TYPE.NONE,
  status: 'Terminated',
  isAuto: false,
};

const AUTO_WITHDRAW_BANK_HEADER: BankRow = {
  bankCode: BankCode.NONE,
  groupBankId: '',
  accountName: 'บัญชีโอนเงิน Auto',
  accountNumber: '',
  bankBalance: 0,
  balance: 0,
  bankId: '',
  bankName: 'AutoWithdrawBank', // REMIND : class name
  promptpay: '',
  trueKey: '',
  type: BANK_TYPE.NONE,
  status: 'Terminated',
  isAuto: false,
};

const MANUAL_WITHDRAW_BANK_HEADER: BankRow = {
  bankCode: BankCode.NONE,
  groupBankId: '',
  accountName: 'บัญชีโอนเงิน Manual',
  accountNumber: '',
  bankBalance: 0,
  balance: 0,
  bankId: '',
  bankName: 'ManualWithdrawBank', // REMIND : class name
  promptpay: '',
  trueKey: '',
  type: BANK_TYPE.NONE,
  status: 'Terminated',
  isAuto: false,
};

const RealtimeTransfer: React.FunctionComponent<IRealtimeTransferProps> = (props) => {
  const [selctedTab, setSelectedTab] = React.useState<TAB>(TAB.BALANCE);
  const [, setModal] = useRecoilState(modalState);
  const [selectedRows, setSelectedRows] = React.useState<BankRow[]>([]);
  const [editingHistoryItem, setEditingHistoryItem] = React.useState<RealtimeTransferHistoryItem>()
  const [form] = Form.useForm<AdjustBalanceForm>();
  const [searchForm] = Form.useForm<HitorySearchCriteria>();
  const [isFormValid, setIsFormValid] = React.useState(false);
  const [searchCriteria, setSearchCriteria] = React.useState<HitorySearchCriteria>({
    offset: 0,
    limit: 10,
    adjustType: 'ALL',
    channel: CHANNEL.ALL,
    status: STATUS.ALL,
    calendar: [dayjs().subtract(1, 'days'), dayjs()],
  });
  const { depositBankList, withdrawBankList, isBanklistLoading, getRealtimeBalance, checkBank, adjustBalance } = useRealtimeTransfer(selctedTab === TAB.LIMIT_TRANSFER);
  const [adjustType, setAdjustType] = React.useState<ADJUST_BALANCE_TYPE>(ADJUST_BALANCE_TYPE.INTERNAL_TRANSFER);
  const {
    historyList,
    total,
    totalWaitingVerify,
    isHistoryFetching,
    getHistoryList,
    editAdjustBalance,
    deleteAdjustBalance,
    confirmAdjustHistory,
  } = useRealtimeTransferHistory(searchCriteria);
  const isAutoWithdrawBankList = (bankrow: BankRow): boolean => bankrow.isWebAuto || bankrow.isAuto
  const manualWithdrawBanklist = React.useMemo(() => withdrawBankList.filter(_bank => !isAutoWithdrawBankList(_bank)), [withdrawBankList]);
  const autoWithdrawBanklist = React.useMemo(() => withdrawBankList.filter(isAutoWithdrawBankList), [withdrawBankList]);
  const mutateOptions = {
    onSuccess: () => {
      form.resetFields();
      setEditingHistoryItem(undefined);
      getHistoryList();
    },
  };

  const bankOrderColumn = (): ColumnType<BankRow>[] => {
    const {
      bankAccount,
      bankBalance,
      balance,
      verifyAt,
    } = BankColumns();

    const onCell = (record: BankRow, index?: number): any => {
      if (record.type === BANK_TYPE.NONE) return { colSpan: 0 };
      return {};
    };
    return [
      {
        ...bankAccount,
        width: 240,
        onCell: (record: BankRow, index) => {
          if (record.type === BANK_TYPE.NONE) return { colSpan: 4 };
          return {};
        },
        render: (value, record, index) => {
          if (record.type === BANK_TYPE.NONE) return (
            <div>
              {record.accountName}
            </div>
          )
          if (bankAccount && bankAccount.render) return bankAccount.render(value, record, index);
          return null;
        }
      },
      {
        ...bankBalance, onCell,
        render: (value, record, index) => {
          const isManualWithdraw = record.type === BANK_TYPE.WITHDRAW && !record.isAuto;
          return (
            <Typography.Text style={{ color: record.balance !== record.bankBalance && !isManualWithdraw ? colors["@red-7"] : '' }}>
              {bankBalance && bankBalance.render && bankBalance.render(value, record, index)}
            </Typography.Text>
          )
        },
      },
      {
        ...balance, onCell,
        render: (value, record, index) => {
          const isManualWithdraw = record.type === BANK_TYPE.WITHDRAW && !record.isAuto;
          return (
            <Typography.Text style={{ color: record.balance !== record.bankBalance && !isManualWithdraw ? colors["@red-7"] : '' }}>
              {balance && balance.render && balance.render(value, record, index)}
            </Typography.Text>
          )
        },
      },
      { ...verifyAt, onCell, },
      SELECTION_COLUMN,
    ]
  };

  const limitTransferColumn = (): ColumnType<BankRow>[] => {
    const {
      bankAccount,
      availableBalance,
      limitTransfer,
      transferedAmount,
    } = BankColumns();

    const onCell = (record: BankRow, index?: number): any => {
      if (record.type === BANK_TYPE.NONE) return { colSpan: 0 };
      return {};
    };
    return [
      {
        ...bankAccount,
        width: 240,
        onCell: (record: BankRow, index) => {
          if (record.type === BANK_TYPE.NONE) return { colSpan: 4 };
          return {};
        },
        render: (value, record, index) => {
          if (record.type === BANK_TYPE.NONE) return (
            <div>
              {record.accountName}
            </div>
          )
          if (bankAccount && bankAccount.render) return bankAccount.render(value, record, index);
          return null;
        }
      },
      { ...limitTransfer, onCell, },
      { ...transferedAmount, onCell, },
      { ...availableBalance, onCell, },
    ]
  };

  const getBaseTableProps = (bankList: BankRow[]) => {
    return {
      isLoading: isBanklistLoading,
      hideOnSinglePage: true,
      isEmpty: bankList.length < 1,
      dataSource: bankList || [],
      totalItem: bankList ? bankList.length : 0,
      limit: 100,
      offset: 0,
      onPaginationChange: (offset: number, limit: number) => {
      }
    }
  };

  const onSelectChange = (newselectedRows: React.Key[], rows: BankRow[]) => {
    setSelectedRows(rows);
  };

  const submitForm = (_form: AdjustBalanceForm) => {
    if (
      _form.depositBankList && _form.withdrawBankList
      && _form.depositBankList.accountName === _form.withdrawBankList.accountName
      && _form.depositBankList.accountNumber === _form.withdrawBankList.accountNumber) {
      return toast.error("บัญชีนำเงินออก และ บัญชีนำเงินเข้าซ้ำกัน");
    }
    const request = {
      amount: _form.amount ? numberWithoutCommas(_form.amount) : 0,
      fee_amount: _form.fee ? numberWithoutCommas(_form.fee) : 0,
      timestamp: _form.datetime.toDate(),
      transfer_type: adjustType,
      channel: _form.channel as CHANNEL,
      remark: _form.remark,
      to_bank_account: _form.depositBankList ? {
        uid: _form.depositBankList.bankId,
        bank_code: _form.depositBankList.bankCode,
        bank_account_name: _form.depositBankList.accountName,
        bank_account_number: _form.depositBankList.accountNumber,
      } : undefined,
      from_bank_account: _form.withdrawBankList ? {
        uid: _form.withdrawBankList.bankId,
        bank_code: _form.withdrawBankList.bankCode,
        bank_account_name: _form.withdrawBankList.accountName,
        bank_account_number: _form.withdrawBankList.accountNumber,
      } : undefined,
    }

    if (editingHistoryItem) {
      editAdjustBalance({
        ...request,
        id: editingHistoryItem.id,
      }, mutateOptions);
    } else {
      adjustBalance(request, {
        onSuccess: (data) => { form.resetFields(); },
      });
    }
  };

  const renderForm = () => {
    const _adjustType = editingHistoryItem ? editingHistoryItem.adjustType : adjustType;
    return (
      <div className='RealtimeTransfer__FormWrapper'>
        {!editingHistoryItem && (
          <>
            <div className='RealtimeTransfer__FormTitle'>ปรับยอดเงินในระบบ</div>
            <Divider style={{ margin: 0 }} />
            <div className='RealtimeTransfer__FormBody'>
              <Tabs size='middle' style={{ marginTop: 10 }} onChange={(tab) => { setAdjustType(tab as ADJUST_BALANCE_TYPE) }}>
                <Tabs.TabPane tab="โอนย้ายเงิน" key={ADJUST_BALANCE_TYPE.INTERNAL_TRANSFER}>
                </Tabs.TabPane>
                <Tabs.TabPane tab="นำเงินเข้า" key={ADJUST_BALANCE_TYPE.DEPOSIT}>
                </Tabs.TabPane>
                <Tabs.TabPane tab="นำเงินออก" key={ADJUST_BALANCE_TYPE.WITHDRAW}>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Dif-" key={ADJUST_BALANCE_TYPE.DIF_MINUS}>
                </Tabs.TabPane>
                <Tabs.TabPane tab="Dif+" key={ADJUST_BALANCE_TYPE.DIF_PLUS}>
                </Tabs.TabPane>
              </Tabs>
            </div>
          </>
        )}
        <Form
          layout="vertical"
          className='RealtimeTransfer__Form'
          form={form}
          onFieldsChange={(e, allField) => {
            const isFormValid = allField.every(field => field.errors && field.errors?.length === 0)
            setIsFormValid(isFormValid);
          }}
          onFinish={submitForm}
        >
          <div className='RealtimeTransfer__FormBody'>
            <Row gutter={[24, 0]}>
              {formConfig[_adjustType].withdrawBankList && (
                <Col span={24}>
                  <Form.Item required rules={[{ required: formConfig[_adjustType].withdrawBankList }]} name="withdrawBankList" label="บัญชีนำเงินออก (-)">
                    <BankSelect bankList={bankList} disabled={editingHistoryItem !== undefined} />
                  </Form.Item>
                </Col>
              )}
              {formConfig[_adjustType].depositBankList && (
                <Col span={24}>
                  <Form.Item required rules={[{ required: formConfig[_adjustType].depositBankList }]} name="depositBankList" label="บัญชีนำเงินเข้า (+)">
                    <BankSelect bankList={bankList} disabled={editingHistoryItem !== undefined} />
                  </Form.Item>
                </Col>
              )}
              {formConfig[_adjustType].amount && (
                <Col span={12}>
                  <Form.Item
                    required
                    name="amount" label="จำนวนเงิน (ไม่มีใส่ 0)"
                    rules={[{
                      required: formConfig[adjustType].amount,
                      validator: amountValidatorAllowZero,
                    }]}
                  >
                    <MoneyInput placeholder="ใส่จำนวนเงิน" size='large' />
                  </Form.Item>
                </Col>
              )}
              {formConfig[_adjustType].fee && (
                <Col span={12}>
                  <Form.Item
                    required
                    name="fee" label="ค่าธรรมเนียม (ไม่มีใส่ 0)"
                    rules={[{
                      required: formConfig[adjustType].fee,
                      validator: amountValidatorAllowZero,
                    }]}
                  >
                    <MoneyInput placeholder="ใส่จำนวนเงิน" size='large' />
                  </Form.Item>
                </Col>
              )}
              {formConfig[_adjustType].datetime && (
                <Col span={24}>
                  <Form.Item required rules={[{ required: formConfig[adjustType].datetime }]} name="datetime" label="วันที่">
                    <DatePicker
                      dropdownClassName='bo__datepicker--hide-ok-button'
                      value={form.getFieldsValue().datetime}
                      size="large"
                      allowClear={false}
                      format="DD/MM/YYYY HH:mm"
                      showTime={{ format: "HH:mm" }}
                      style={{ width: "100%" }}
                      onSelect={(value) => { form.setFieldsValue({ datetime: value }) }}
                    />
                  </Form.Item>
                </Col>
              )}
              {formConfig[_adjustType].channel && (
                <Col span={24}>
                  <Form.Item required rules={[{ required: formConfig[adjustType].channel }]} name="channel" label="ช่องทาง">
                    <Select size='large' placeholder="เลือกช่องทาง" options={channelOptions} />
                  </Form.Item>
                </Col>
              )}
              {formConfig[_adjustType].remark && (
                <Col span={24}>
                  <Form.Item name="remark" label="สาเหตุ">
                    <Input.TextArea placeholder='ใส่สาเหตุ' />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </div>
          {!editingHistoryItem && (
            <>
              <Divider style={{ margin: 0 }} />
              <div className='RealtimeTransfer__FormFooter'>
                <Row >
                  <Col span={12} style={{ textAlign: 'left' }}>
                    <Button size="large" style={{ width: 100 }} onClick={() => { form.resetFields(); }} >ล้างข้อมูล</Button>
                  </Col>
                  <Col span={12} style={{ textAlign: 'right' }}>
                    <Button disabled={!isFormValid} htmlType='submit' size="large" style={{ width: 144 }} type="success">
                      <Icon name='filled-select-1' />&nbsp;
                      ปรับยอดเงิน
                    </Button>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Form>
      </div>
    )
  };

  const onVerify = () => {
    const checkingBankList = selectedRows.filter(bank => bank.bankCode !== BankCode.NONE);
    checkBank({
      depositAccount: checkingBankList.filter(_bank => _bank.type === BANK_TYPE.DEPOSIT).map(_bank => _bank.bankId),
      withdrawAccount: checkingBankList.filter(_bank => _bank.type === BANK_TYPE.WITHDRAW).map(_bank => _bank.bankId),
    })
  };

  const rowSelection = {
    selectedRows,
    onChange: onSelectChange,
  };

  const bankList = React.useMemo(() => ([
    {
      label: 'บัญชีรับเงิน',
      options: depositBankList,
    },
    {
      label: 'บัญชีโอนเงิน Auto',
      options: autoWithdrawBanklist,
    },
    {
      label: 'บัญชีโอนเงิน Manual',
      options: manualWithdrawBanklist,
    },
  ]), [depositBankList, autoWithdrawBanklist, manualWithdrawBanklist]);

  let bankDataTable: BankRow[] = [];
  if (depositBankList.length > 0) {
    bankDataTable = [
      ...bankDataTable,
      DEPOSIT_BANK_HEADER,
      ...depositBankList,
    ];
  }
  if (autoWithdrawBanklist.length > 0) {
    bankDataTable = [
      ...bankDataTable,
      AUTO_WITHDRAW_BANK_HEADER,
      ...autoWithdrawBanklist,
    ];
  }
  if (manualWithdrawBanklist.length > 0) {
    bankDataTable = [
      ...bankDataTable,
      MANUAL_WITHDRAW_BANK_HEADER,
      ...manualWithdrawBanklist,
    ];
  }

  const historyColumn: ColumnType<RealtimeTransferHistoryItem>[] = [
    {
      title: "วันที่ปรับบัญชี",
      dataIndex: "createdAt",
      width: 100,
      render: (value) => <DateTime value={value} />,
    }, {
      title: 'ประเภท',
      dataIndex: 'adjustType',
      key: 'adjustType',
      width: 150,
      render: (value: ADJUST_BALANCE_TYPE, row: RealtimeTransferHistoryItem) => {
        const overrideValue = row.channel === 'FEE' ? ADJUST_BALANCE_TYPE.FEE : value
        return (
          <Space>
            <Typography.Text style={{ color: adjustTypeConfig[overrideValue].color }}>
              {adjustTypeConfig[overrideValue].text}
            </Typography.Text>
          </Space>
        );
      },
    }, {
      title: 'จำนวนเงิน (THB)',
      dataIndex: 'amount',
      key: 'amount',
      width: 140,
      render: (value: number) => {
        return (
          <AmountColumn amount={value} />
        )
      },
    }, {
      title: 'ค่าธรรมเนียม (THB)',
      dataIndex: 'fee',
      key: 'fee',
      width: 140,
      render: (value: number) => {
        return (
          <AmountColumn amount={value} />
        )
      },
    }, {
      title: 'บัญชีนำเงินออก (-)',
      dataIndex: 'withdrawAccount',
      key: 'withdrawAccount',
      width: 200,
      render: (value: BankAccount, historyItem: RealtimeTransferHistoryItem) => {
        return (
          <Space>
            <Account {...historyItem.withdrawAccount} />
          </Space>
        )
      },
    }, {
      title: 'บัญชีนำเงินเข้า (+)',
      dataIndex: 'depositAccount',
      key: 'depositAccount',
      width: 200,
      render: (value: BankAccount, historyItem: RealtimeTransferHistoryItem) => {
        return (
          <Space>
            <Account {...historyItem.depositAccount} />
          </Space>
        )
      },
    }, {
      title: 'ช่องทาง',
      dataIndex: 'channel',
      key: 'channel',
      render: (value: CHANNEL) => {
        return (
          <Space>
            {channelConfig[value].label}
          </Space>
        )
      },
    }, {
      title: 'สาเหตุ',
      dataIndex: 'remark',
      key: 'remark',
      width: 180,
      render: (value: string) => {
        return (
          <Space>
            {value}
          </Space>
        )
      },
    }, {
      title: '',
      dataIndex: 'logs',
      key: 'logs',
      width: 50,
      render: (logs, historyItem: RealtimeTransferHistoryItem) => {
        return (
          <Space>
            <ViewMoreButton
              size="small"
              onClick={() => NiceModal.show(RealtimeTransferViewMoreModal, historyItem)}
            />
          </Space>
        )
      },
    }, {
      title: '',
      dataIndex: 'action',
      key: 'action',
      width: 50,
      fixed: 'right',
      render: (logs, historyItem: RealtimeTransferHistoryItem) => {
        if (searchCriteria.status === STATUS.ALL) {
          const menus = (
            <Menu>
              <Menu.Item
                key="edit"
                style={{ padding: '1rem' }}
                onClick={() => setEditingHistoryItem(historyItem)}
              >
                <Typography.Text style={{ fontSize: '1rem' }}  >
                  <Icon name="outline-edit-1" size="1.5rem" />
                  &nbsp;
                  แก้ไขรายการ
                </Typography.Text>
              </Menu.Item>
              <Menu.Item
                key="delete"
                style={{ padding: '1rem' }}
                onClick={() => {
                  setModal({
                    isModalVisible: true,
                    type: 'warning',
                    title: 'ยกเลิกรายการ',
                    content: 'ต้องการยกเลิกการปรับปรุงยอดเงินในระบบรายการนี้ใช่หรือไม่',
                    buttonType: 'question',
                    onConfirm: () => {
                      deleteAdjustBalance({ id: historyItem.id }, mutateOptions)
                    },
                  })
                }}
              >
                <Typography.Text style={{ fontSize: '1rem' }} >
                  <Icon name="outline-close-2" size="1.5rem" />
                  &nbsp;
                  ยกเลิกรายการ
                </Typography.Text>
              </Menu.Item>
            </Menu>
          );
          return (
            <Space>
              <Dropdown overlay={menus}>
                <Button size="small" type="lightdark" icon={<OutlineEdit1 />} hidden={historyItem.isAutoSystem} />
              </Dropdown>
            </Space>
          )
        }
        return (
          <Button
            size="small"
            type="success"
            icon={<Icon name="outline-check-1-1" />}
            style={{ lineHeight: 1 }}
            onClick={() => {
              setModal({
                isModalVisible: true,
                type: 'warning',
                title: 'ยืนยันการตรวจสอบ',
                content: 'ต้องการยืนยันรายการนี้มีเงินเข้าบัญชีธนาคารแล้วใช่หรือไม่',
                buttonType: 'question',
                onConfirm: () => {
                  confirmAdjustHistory({ id: historyItem.id }, mutateOptions);
                },
              })
            }}
          />
        );
      },
    },
  ];

  const shouldDisableCheckBankButton = React.useMemo(() => {
    return selectedRows.filter(_bank => _bank.type !== BANK_TYPE.NONE).length === 0;
  }, [selectedRows]);

  React.useEffect(() => {
    if (editingHistoryItem) {
      form.setFieldsValue({
        amount: editingHistoryItem.amount,
        datetime: datetime(editingHistoryItem.createdAt),
        depositBankList: editingHistoryItem.depositAccount,
        withdrawBankList: editingHistoryItem.withdrawAccount,
        fee: editingHistoryItem.fee,
        remark: editingHistoryItem.remark,
        channel: editingHistoryItem.channel,
      });
    } else {
      form.resetFields();
    }
    setIsFormValid(false);
  }, [editingHistoryItem]);

  React.useEffect(() => {
    searchForm.setFieldsValue(searchCriteria);
  }, []);

  React.useEffect(() => {
    form.setFieldsValue({
      datetime: datetime(new Date().toString()),
    })
  }, [selctedTab])

  React.useEffect(() => {
    getHistoryList();
  }, [searchCriteria]);

  return (
    <div id="RealtimeTransfer">
      <Tabs
        onChange={(_tab) => {
          setSelectedTab(_tab as TAB)
          if (_tab === TAB.HISTORY) {
            const updateCalendar: HitorySearchCriteria = {
              ...searchCriteria,
              calendar: [dayjs().subtract(1, 'days'), dayjs()],
            }
            searchForm.setFieldsValue(updateCalendar);
            setSearchCriteria(updateCalendar);
          } else {
            getRealtimeBalance();
          }
        }}
      >
        <Tabs.TabPane tab="ยอดเงินในบัญชี" key={TAB.BALANCE} >
          <Row gutter={[24, 24]}>
            <Col span={14}>
              <DeskPanel body={(
                <>
                  <Table
                    {...getBaseTableProps(bankDataTable)}
                    rowClassName={(record: BankRow) => {
                      if (record.type === BANK_TYPE.NONE) return record.bankName;
                      return '';
                    }}
                    scroll={{ y: '60vh' }}
                    scrollX={800}
                    footer={() => (
                      <div style={{ textAlign: 'right' }}>
                        <Button
                          type='primary'
                          size='large'
                          style={{ width: 120 }}
                          onClick={onVerify}
                          disabled={shouldDisableCheckBankButton}
                        >
                          ตรวจสอบ
                        </Button>
                      </div>
                    )}
                    tableLayout="auto"
                    columns={bankOrderColumn()}
                    rowSelection={{
                      ...rowSelection,
                      columnWidth: 50,
                      fixed: 'right',
                      renderCell: (value, record, index, originNode) => {
                        return record.key ? originNode : null;
                      },
                    }}
                  />
                </>
              )} />
            </Col>
            <Col span={10}>
              {renderForm()}
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="ลิมิตการทำรายการ" key={TAB.LIMIT_TRANSFER}>
          <Row gutter={[24, 24]}>
            <Col span={14}>
              <DeskPanel body={(
                <>
                  <Table
                    {...getBaseTableProps(bankDataTable)}
                    rowClassName={(record: BankRow) => {
                      if (record.type === BANK_TYPE.NONE) return record.bankName;
                      return '';
                    }}
                    scroll={{ y: '70vh' }}
                    scrollX={800}
                    tableLayout="auto"
                    columns={limitTransferColumn()}
                  />
                </>
              )} />
            </Col>
            <Col span={10}>
              {renderForm()}
            </Col>
          </Row>
        </Tabs.TabPane>
        <Tabs.TabPane tab="ประวัติปรับยอดเงิน" key={TAB.HISTORY}>
          <DeskPanel body={(
            <>
              <Row style={{ marginBottom: 10 }}>
                <Col flex="auto">
                  <RadioGroup onChange={(e) => { setSearchCriteria({ ...searchCriteria, status: e.target.value }) }}>
                    <Radio.Button className={searchCriteria.status === STATUS.ALL ? 'ant-radio-button-wrapper-checked' : ''} key={STATUS.ALL} value={STATUS.ALL} >{`ทั้งหมด (${total})`}</Radio.Button>
                    <Radio.Button className={searchCriteria.status === STATUS.WAITING_VERIFY ? 'ant-radio-button-wrapper-checked' : ''} key={STATUS.WAITING_VERIFY} value={STATUS.WAITING_VERIFY}>{`รอตรวจสอบ (${totalWaitingVerify})`}</Radio.Button>
                  </RadioGroup>
                </Col>
                <Col flex="590px">
                  <Form
                    layout="inline"
                    form={searchForm}
                    onFinish={(e) => {
                      const overrideCriteria = adjustTypeConfig[e.adjustType].searchCriteria
                      setSearchCriteria({
                        ...searchCriteria,
                        ...e,
                        ...overrideCriteria,
                        offset: 0,
                      });
                    }}
                  >
                    <Form.Item name="calendar">
                      <DatePicker.RangePicker
                        size="large"
                        allowClear={false}
                        format="DD/MM/YYYY HH:mm"
                        showTime={{ format: "HH:mm" }}
                        style={{ width: "340px" }}

                      />
                    </Form.Item>
                    <Form.Item name="adjustType">
                      <Select size="large" style={{ width: 175 }}>
                        <Select.Option value={'ALL'}>ทุกรายการ</Select.Option>
                        <Select.Option value={ADJUST_BALANCE_TYPE.INTERNAL_TRANSFER}>{adjustTypeConfig[ADJUST_BALANCE_TYPE.INTERNAL_TRANSFER].text}</Select.Option>
                        <Select.Option value={ADJUST_BALANCE_TYPE.DEPOSIT}>{adjustTypeConfig[ADJUST_BALANCE_TYPE.DEPOSIT].text}</Select.Option>
                        <Select.Option value={ADJUST_BALANCE_TYPE.WITHDRAW}>{adjustTypeConfig[ADJUST_BALANCE_TYPE.WITHDRAW].text}</Select.Option>
                        <Select.Option value={ADJUST_BALANCE_TYPE.DIF_PLUS}>{adjustTypeConfig[ADJUST_BALANCE_TYPE.DIF_PLUS].text}</Select.Option>
                        <Select.Option value={ADJUST_BALANCE_TYPE.DIF_MINUS}>{adjustTypeConfig[ADJUST_BALANCE_TYPE.DIF_MINUS].text}</Select.Option>
                        <Select.Option value={ADJUST_BALANCE_TYPE.FEE}>{adjustTypeConfig[CHANNEL.FEE].text}</Select.Option>
                      </Select>
                    </Form.Item>
                    <Button htmlType="submit" size="large" type="primary" style={{ width: 40 }}><Icon name="outline-search-2" size="1.5rem" /></Button>
                  </Form>
                </Col>
              </Row>
              <Table
                columns={historyColumn}
                offsetBuffer={1}
                dataSource={historyList}
                isLoading={isHistoryFetching}
                isEmpty={historyList.length < 1}
                totalItem={total}
                limit={searchCriteria.limit}
                offset={searchCriteria.offset}
                onPaginationChange={(offset: number, limit: number) => {
                  setSearchCriteria({
                    ...searchCriteria,
                    limit,
                    offset,
                  });
                }}
              />
            </>
          )} />
        </Tabs.TabPane>
      </Tabs>
      <Modal
        title="ปรับยอดเงินในระบบ"
        visible={editingHistoryItem !== undefined}
        onCancel={() => setEditingHistoryItem(undefined)}
        onOk={() => { submitForm(form.getFieldsValue()) }}
        footer={(<>
          <Button size="large" style={{ width: 100 }} onClick={() => { setEditingHistoryItem(undefined); }} >ยกเลิก</Button>
          <Button disabled={!isFormValid} size="large" style={{ width: 144 }} type="success" onClick={() => submitForm(form.getFieldsValue())}>
            <Icon name='filled-select-1' />&nbsp;
            ปรับยอดเงิน
          </Button>
        </>)}
      >
        {renderForm()}
      </Modal>
    </div>
  );
};

export default RealtimeTransfer;
