import { useQuery, useMutation } from "react-query";
import { toast } from "src/components";
import { mapToBankName } from "src/mapper/mapToBank";
import { CamelCaseBaseResponse } from "src/services/service.type";
import { BankAccountStatus, BankCode, BankRow, BANK_TYPE } from "src/types";
import { api } from "src/utils";
import { onlyEnableBank, sortingBank } from "src/utils/bank";

export enum CHANNEL {
  ALL = 'ALL',
  ATM = 'ATM',
  FEE = 'FEE',
  IBANK = 'IBANK',
  MBANK = 'MBANK',
  BRANCH = 'BRANCH',
}

export enum ADJUST_BALANCE_TYPE {
  INTERNAL_TRANSFER = 'TRANSFER',
  DEPOSIT = 'DEPOSIT',
  WITHDRAW = 'WITHDRAW',
  DIF_PLUS = 'DIF_PLUS',
  DIF_MINUS = 'DIF_MINUS',
  FEE = 'FEE'
};

export type BankBalanceItem = {
  bgbBankAccountName: string;
  bgbBankAccountNumber: string;
  bgbBankIsAuto?: boolean; // Deposit account
  bgbBankIsAutoWeb?: boolean; // Withdraw account
  bgbBankIsAutoBo?: boolean; // Withdraw account
  bgbBankNickName: string;
  bgbBankStatus: BankAccountStatus;
  bgbBankUid: string;
  groupBankBankCode: BankCode;
  groupBankName: string;
  groupBankUid: string;
  latestBalance: number | null;
  latestVerifiedTimestamp: Date | null;
  bgbBankLimitAmount: number;
  transferBalance: number | null;
  bgbBankTodayTransactionAmount?: number;
  bgbBankAvailableAmount?: number;
}

export type LimitTransferItem = BankBalanceItem & {
}

export type BankListData<T> = {
  depositAccount: T[];
  withdrawAccount: T[];
}
export type BankBalanceList = BankListData<BankBalanceItem>;

export const mapBankBalanceToBankRow = (_bank: BankBalanceItem, type: BANK_TYPE): BankRow => {
  const baseRow: BankRow = {
    type,
    bankId: _bank.bgbBankUid,
    groupBankId: _bank.groupBankUid,
    bankCode: _bank.groupBankBankCode,
    key: _bank.bgbBankUid,
    bankName: mapToBankName(_bank.groupBankBankCode),
    accountName: _bank.bgbBankAccountName,
    accountNumber: _bank.bgbBankAccountNumber,
    promptpay: '',
    trueKey: '',
    isAuto: _bank.bgbBankIsAuto || false,
    status: _bank.bgbBankStatus,
    verifyAt: _bank.latestVerifiedTimestamp || undefined,
    balance: _bank.transferBalance || 0,
    bankBalance: _bank.latestBalance || 0,
    limitTransfer: _bank.bgbBankLimitAmount,
    availableBalance: _bank.bgbBankAvailableAmount,
    transferedAmount: _bank.bgbBankTodayTransactionAmount,
  };

  if (type === BANK_TYPE.DEPOSIT) {
    return {
      ...baseRow,
      isAuto: _bank.bgbBankIsAuto || false,
    }
  }
  return {
    ...baseRow,
    isAuto: _bank.bgbBankIsAutoBo || false,
    isWebAuto: _bank.bgbBankIsAutoWeb || false,
  };
};

export type CheckBalanceRequest = BankListData<string>;

const checkBalance = async (request: CheckBalanceRequest) => {
  const { data: response } = await api.post<CamelCaseBaseResponse<{}>>('/realtime-transfer/verify-bgb-bank', request);
  return response.data;
};

export type AdjustBalanceRequest = {
  from_bank_account?: {
    uid: string;
    bank_code: BankCode;
    bank_account_name: string;
    bank_account_number: string;
  },
  to_bank_account?: {
    uid: string;
    bank_code: BankCode;
    bank_account_name: string;
    bank_account_number: string;
  },
  amount: number;
  fee_amount: number;
  timestamp: Date;
  transfer_type: ADJUST_BALANCE_TYPE;
  channel: CHANNEL;
  remark: string;
}

const postAdjustBalance = async (request: AdjustBalanceRequest) => {
  const { data: response } = await api.post<CamelCaseBaseResponse<{}>>('/realtime-transfer/adjust-bgb-balance', request);
  return response.data;
};

export const useRealtimeTransfer = (withLimitation: boolean) => {
  const { data: bankBalanceList, isFetching: isBankBalanceListFetching, refetch: getRealtimeBalance } = useQuery(
    ["realtime", "transfer", "bgb-bank", "limit-transfer", withLimitation],
    async () => {
      const params = new URLSearchParams({
        with_limitation: `${withLimitation}`
      })
      const response = await api.get<CamelCaseBaseResponse<BankBalanceList>>(`/realtime-transfer/bgb-bank?${params}`);
      return response.data.data;
    },
    {
      select: (responseData) => {
        return {
          depositBankList: responseData.depositAccount.map((bank) => mapBankBalanceToBankRow(bank, BANK_TYPE.DEPOSIT)).filter(onlyEnableBank).sort(sortingBank),
          withdrawBankList: responseData.withdrawAccount.map((bank) => mapBankBalanceToBankRow(bank, BANK_TYPE.WITHDRAW)).filter(onlyEnableBank).sort(sortingBank),
        };
      },
    },
  );

  const { mutate: checkBank, } = useMutation(checkBalance, {
    onSuccess: data => {
      toast.success('บันทึกการตรวจสอบสำเร็จ');
      getRealtimeBalance();
    },
    onError: data => {
      toast.error('บันทึกการตรวจสอบไม่สำเร็จ');
      getRealtimeBalance();
    },
  })

  const { mutate: adjustBalance, } = useMutation(postAdjustBalance, {
    onSuccess: data => {
      toast.success('ปรับบัญชีสำเร็จ');
      getRealtimeBalance();
    },
    onError: data => {
      toast.error('ปรับบัญชีไม่สำเร็จ');
      getRealtimeBalance();
    },
  })

  return {
    getRealtimeBalance,
    checkBank,
    adjustBalance,
    isBanklistLoading: isBankBalanceListFetching,
    depositBankList: bankBalanceList?.depositBankList || [],
    withdrawBankList: bankBalanceList?.withdrawBankList || [],
  }
};
